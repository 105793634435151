import styled from '@emotion/styled'
import Slider from 'react-slick'
import { useEffect, useRef } from 'react'
import { gsap } from 'gsap'

import { breakpoint } from '../../utils'
import { paddingFooter } from '../../components/Footer'

const settings = {
  dots: false,
  arrows: false,
  fade: false,
  autoplay: false,
  infinite: true,
  speed: 500,
  autoplaySpeed: 4000,
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [
    // {
    //   breakpoint: 768,
    //   settings: {
    //     slidesToShow: 3,
    //     slidesToScroll: 3,
    //     dots: true
    //   }
    // }
  ]
}

const WhereToBuy = ({ items }) => {
  const sliderRef = useRef()

  const pageRef = useRef()

  useEffect(() => {
    const page = pageRef.current
    setTimeout(() => {
      const animationSelectors = page.querySelectorAll('.animate')
      gsap.fromTo(
        animationSelectors,
        {
          opacity: 0,
          y: 120
        },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          stagger: 0.2,
          scrollTrigger: {
            trigger: page,
            start: 'top bottom-=200',
            once: true
          }
        }
      )
    }, 300)
  }, [])

  const opacity = (item) => {
    if (item.active) return '1'
    return '0.5'
  }

  return (
    <Style ref={pageRef}>
      <div className='wrapper'>
        <div className='container'>
          <div className='row'>
            <div className='D-3 M-12 vertical-middle'>
              <h1 className='animate'>Where to buy</h1>
            </div>
            <div className='D-9 M-12 vertical-middle'>
              <div className='box-slider animate'>
                <img
                  className='prev'
                  onClick={() => sliderRef.current.slickPrev()}
                  src='/images/ico-arrow-slider-left.webp'
                  alt=''
                />
                <img
                  className='next'
                  onClick={() => sliderRef.current.slickNext()}
                  src='/images/ico-arrow-slider-right.webp'
                  alt=''
                />
                <Slider ref={sliderRef} {...settings}>
                  {items.map((item, i) => (
                    <div key={i}
                      className={`slider-item`}
                    >
                      <img key={i} src={item.image} alt='' style={{ opacity: opacity(item) }} />
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Style>
  )
}

const Style = styled.div`
  label: WhereToBuy;

  background: #fff url('/images/img-where-to-buy.webp') center center no-repeat;
  background-size: cover;

  .wrapper {
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 160px 20px;
  }

  h1 {
    font-size: 44px;
    width: 150px;
    text-transform: uppercase;
  }

  .box-slider {
    position: relative;
    margin: 0 60px;

    .prev {
      position: absolute;
      top: 50%;
      left: -40px;
      transform: translateY(-50%);
      width: 27px;
      cursor: pointer;
    }

    .next {
      position: absolute;
      top: 50%;
      right: -40px;
      transform: translateY(-50%);
      width: 27px;
      cursor: pointer;
    }
  }

  .slider-item {
    padding: 20px;
    &.rescale {
      > img {
        transform: scale(0.6);
      }
    }
    img {
      width: 100%;
    }
  }

  ${breakpoint('M')} {
    background: #fff url('/images/img-where-to-buy-mobile.webp') center center no-repeat;
    background-size: cover;

    .wrapper {
      padding-top: 60px;
      min-height: auto;
    }

    .box-slider {
      margin: 0;
    }

    .prev,
    .next {
      display: none;
    }

    .slider-item {
      padding: 10px;
    }

    h1 {
      font-size: 20px;
      width: 75px;
      line-height: 1.2;
    }
  }

  ${paddingFooter}
`

export default WhereToBuy
