import styled from '@emotion/styled'
import { useState } from 'react'
import { fluidSizing } from '../../utils'

export default function Collapse({ title, maxFontSize = 20, color = '#4b0585', children }) {
  const [show, setShow] = useState(false)

  return (
    <Style className='collapse' color={color} maxFontSize={maxFontSize}>
      <div className='title' onClick={() => setShow(!show)}>
        <span>{title}</span>
        <div className={`arrow-down`}>{show ? '-' : '+'}</div>
      </div>
      {show && <div className='detail'>{children}</div>}
    </Style>
  )
}

const Style = styled('div')`
  label: Collapse;

  padding: 23px 0;
  position: relative;
  color: ${(p) => p.color};
  font-family: 'Kanit';

  .title {
    font-weight: 600;
    padding-right: 60px;
    cursor: pointer;
    ${(p) =>
      p.maxFontSize &&
      fluidSizing('font-size', { SM: 14, M: 14, T: p.maxFontSize, D: p.maxFontSize })}
  }

  + .collapse {
    border-top: 1px solid ${(p) => p.color};
  }

  .arrow-down {
    display: inline-flex;
    position: absolute;
    ${fluidSizing('top', { SM: 15, M: 15, T: 20, D: 20 })}
    right: 30px;
    transition: all 0.3s ease-in-out;

    /* &.show {
      transform: rotate(-180deg);
    } */
  }

  .detail {
    margin-top: 18px;
    padding-right: 70px;
    font-weight: 300;
    line-height: 1.32;
    border-left: 1px solid ${(p) => p.color};
    padding-left: 30px;
    ${fluidSizing('font-size', { SM: 14, M: 14, T: 19, D: 19 })}
  }

  @media (max-width: 758px) {
    padding: 16px 0;

    .title {
      padding-right: 40px;
    }

    .arrow-down {
      right: 15px;
    }

    .detail {
      padding-right: 0;
    }
  }
`
