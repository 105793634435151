import styled from '@emotion/styled'
import { Helmet } from 'react-helmet'
import { paddingFooter } from '../components/Footer'
import { breakpoint, fluidSizing } from '../utils'
import Translate from '../components/Translate'
import { useEffect, useRef } from 'react'
import { gsap } from 'gsap'

const Brand = () => {
  // const appData = useSelector((state) => state.data)

  // if (isEmpty(appData.content)) return null

  // const { content } = appData
  const pageRef = useRef()

  useEffect(() => {
    setTimeout(() => {
      const animationSelectors = pageRef.current.querySelectorAll('.animate')

      gsap.fromTo(
        animationSelectors,
        {
          opacity: 0,
          y: 40
        },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          stagger: 0.2
        }
      )
    }, 100)
  }, [])

  return (
    <>
      <Helmet>
        <title>Brand</title>
        <meta name='title' content='Brand' />
        <meta name='description' content='' />
      </Helmet>

      <Style ref={pageRef}>
        <div className='content'>
          <div className='box-detail animate'>
            <h1>
              <span>+</span> CORE VALUE
            </h1>
            <p>
              <Translate
                en='A new type of spread product with new, beneficial and nutritional ingredients, adding a green color to your life and a fun twist to enjoy eating bread spread.'
                th='สรรสร้างผลิตภัณฑ์ทาขนมปังรูปแบบใหม่ ๆ โดยคัดสรรวัตถุดิบที่แปลกใหม่และมีคุณประโยชน์ เพิ่มสีสันลูกเล่น ทวิสต์การทานผลิตภัณฑ์ทาขนมปัง ให้สนุกหลุดกรอบเดิม ๆ'
              />
            </p>
          </div>
        </div>
      </Style>
    </>
  )
}

const Style = styled('div')`
  label: Brand;

  position: relative;
  min-height: 100vh;
  background: #fff url('/images/img-brand.webp') center center no-repeat;
  background-size: cover;

  .content {
    position: relative;
    min-height: 100vh;
    padding: 232px 20px;
  }

  .box-detail {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding-left: 150px;

    > h1 {
      ${fluidSizing('font-size', { SM: 20, M: 20, T: 45, D: 45 })}
      color: #482c89;

      > span {
        color: #993486;
      }
    }

    > p {
      font-family: 'Kanit';
      font-weight: 300;
      line-height: 1.4;
      color: #4b0585;
      ${fluidSizing('font-size', { SM: 14, M: 14, T: 19, D: 19 })}
    }
  }

  ${breakpoint('M')} {
    background: #fff url('/images/img-brand-mobile.webp') center center no-repeat;
    background-size: cover;

    .content {
      position: relative;
      min-height: 100vh;
      padding: 160px 20px;
    }

    .box-detail {
      width: 100%;
      max-width: 400px;
      margin: 0 auto;
      padding-left: 250px;
    }
  }

  ${breakpoint('M')} {
    .content {
      position: relative;
      min-height: 100vh;
      padding: 160px 20px;
    }

    .box-detail {
      width: 100%;
      max-width: 400px;
      margin: 0 auto;
      padding-left: 200px;
    }
  }

  ${paddingFooter}
`

export default Brand
