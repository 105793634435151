const createActionWithFetching =
  ({ callAction, onError, onFinally }) =>
  async (dispatch, getState) => {
    try {
      await callAction(dispatch, getState)
    } catch (error) {
      console.log('createActionWithFetching.error', error)
      if (onError) onError()
    } finally {
      if (onFinally) onFinally()
    }
  }

export default createActionWithFetching
