import { Helmet } from 'react-helmet'
import { useController, useForm, Controller } from 'react-hook-form'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import countryList from 'react-select-country-list'
// import Select from 'react-select'
// import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import { useTranslation } from 'react-i18next'
import ReactFlagsSelect from 'react-flags-select';

import styled from '@emotion/styled'

import { breakpoint, fluidSizing } from '../utils'
import { paddingFooter } from '../components/Footer'
import Collapse from '../components/Collapse'
import Translate from '../components/Translate'
import Modal, { openModal } from '../components/Modal'
import Button from '../components/Button'
import { getReviews } from '../actions/action'
import Rating from '../components/Rating'
import { fetchCreateReview } from '../api/fetchers'

const emailFormat =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const lengthLimit = /^.{1,150}$/

const messagePlaceholder = {
  en: "Maximum 150 characters",
  th: "ไม่เกิน 150 ตัวอักษร"
}
const selectCountryPlaceHolder = {
  en: "Select Country",
  th: "เลือกประเทศ"
}

const FAQ = () => {
  const { i18n } = useTranslation()

  const [selected, setSelected] = useState('');
  const [sending, setSending] = useState(false)
  const appData = useSelector((state) => state.data)
  const dispatch = useDispatch()
  const { control, reset, handleSubmit } = useForm()
  const countryOptions = useMemo(() => countryList().getData(), [])
  const options = useMemo(() => countryList().getData(), [])

  useEffect(() => {
    dispatch(getReviews())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const inputName = useController({
    control,
    name: 'fullname',
    defaultValue: '',
    rules: {
      required: '*Please enter name'
    }
  })

  const inputEmail = useController({
    control,
    name: 'email',
    defaultValue: '',
    rules: {
      required: '*Please enter email',
      pattern: {
        value: emailFormat,
        message: '*Please enter a valid email address'
      }
    }
  })

  const inputCountry = useController({
    control,
    name: 'country',
    defaultValue: '',
    // rules: {
      // required: '*Please enter country'
    // }
  })

  const inputMessage = useController({
    control,
    name: 'message',
    defaultValue: '',
    rules: {
      required: '*Please enter message',
      pattern: {
        value: lengthLimit,
        message: '*Please do not add more than 150 characters'
      }
    }
  })

  const onSubmit = async (formData) => {
    console.log('Form data', formData)
    if (selected.length <= 0) return
    formData.country = selected

    const dataToSend = {
      title: `[:th]${formData.fullname}[:en]${formData.fullname}`,
      status: 'draft',
      fields: {
        ...formData
      }
    }

    try {
      setSending(true)
      const res = await fetchCreateReview(dataToSend)
      console.log('res', res)
      if (res.status === 201) {
        reset({
          fullname: '',
          email: '',
          country: '',
          message: ''
        })
        openModal('create-review-success')
        setSelected('');
      } else {
        openModal('error')
      }
    } catch (error) {
      console.log('Review Catch', error)
      openModal('error')
    } finally {
      setSending(false)
    }
  }

  if (isEmpty(appData.content)) return null

  const { content } = appData
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: '#000'
    })
  }

  return (
    <>
      <Helmet>
        <title>FAQ</title>
        <meta name='title' content='FAQ' />
        <meta name='description' content='' />
      </Helmet>

      <Style>
        <div className='box-faq'>
          <div className='image'></div>
          <div className='faq-list'>
            <h1>FAQ</h1>
            {content.faq_list.map((item, i) => (
              <Collapse key={i} title={item.question} color='#fff'>
                {item.anwser}
              </Collapse>
            ))}
          </div>
        </div>
        <div className='content'>
          <div className='container'>
            <div className='row'>
              <div className='D-5 M-12'>
                <h1>CUSTOMER REVIEWS AND RATING </h1>
                <p>
                  <Translate
                    en='Share your experience and feelings about Benas'
                    th='ร่วมแชร์ประสบการณ์และความประทับใจที่คุณมีต่อบีนาส'
                  />
                </p>
                <div className='form'>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='field-item'>
                      <div className='label required'>
                        <Translate en='Fullname' th='ชื่อ - นามสกุล' />
                      </div>
                      <div className='input'>
                        <input type='text' {...inputName.field} />
                        <div className='error-message'>{inputName.fieldState.error?.message}</div>
                      </div>
                    </div>
                    <div className='field-item'>
                      <div className='label required'>
                        <Translate en='Email' th='อีเมล' />
                      </div>
                      <div className='input'>
                        <input type='text' {...inputEmail.field} />
                        <div className='error-message'>{inputEmail.fieldState.error?.message}</div>
                      </div>
                    </div>
                    <div className='field-item'>
                      <div className='label required'>
                        <Translate en='Country' th='ประเทศ' />
                      </div>
                      <div className='input'>
                        {/* <Select
                          {...inputCountry.field}
                          styles={customStyles}
                          placeholder={selectCountryPlaceHolder[i18n.language]}
                          options={countryOptions.map((item, i) => ({
                            value: item.value,
                            label: "<span style={{ marginLeft: 5 }}className={`fi fi-${item.acf.country.toLowerCase()}`}></span>"
                            // label: `${getUnicodeFlagIcon(item.value)} ${item.label}`
                          }))}
                        /> */}
                        {/* <select {...inputCountry.field}>
                          <option value=''>
                            {selectCountryPlaceHolder[i18n.language]}
                          </option>
                          {countryOptions.map((item, i) => (
                            <option key={i} value={item.value}>
                              <span style={{ marginLeft: 5 }} className={`fi fi-${item?.acf?.country.toLowerCase()}`}></span>
                              {item.label}
                            </option>
                          ))}
                        </select> */}
                        <ReactFlagsSelect
                          placeholder={selectCountryPlaceHolder[i18n.language]}
                          searchable
                          selected={selected}
                          onSelect={code => setSelected(code)}
                          className="flag-select"
                          {...inputCountry.field}
                        />
                        <div className='error-message'>
                          {inputCountry.fieldState.error?.message}
                        </div>
                      </div>
                    </div>
                    <div className='field-item'>
                      <div className='label required'>
                        <Translate en='Message' th='เขียนรีวิว' />
                      </div>
                      <div className='input'>
                        <textarea maxlength="150" type='text' rows='6' {...inputMessage.field} placeholder={messagePlaceholder[i18n.language]} />
                        <div className='error-message'>
                          {inputMessage.fieldState.error?.message}
                        </div>
                        <div className='rating'>
                          <div>
                            <Controller
                              control={control}
                              name='rate'
                              defaultValue={null}
                              rules={{ required: '*Please enter rating' }}
                              render={({ field: { value, onChange }, fieldState }) => (
                                <>
                                  <Rating key={sending} value={value} onChange={onChange} />
                                  <div className='error-message'>{fieldState.error?.message}</div>
                                </>
                              )}
                            />
                          </div>
                          <Button fullwidth loading={sending}>
                            <Translate
                              en='Send'
                              th='ส่ง'
                            />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className='D-1 M-12'></div>
              <div className='D-6 M-12'>
                <div className='box-reviews'>
                  {appData.reviews.length > 0 ? (
                    <div className='review-list'>
                      {appData.reviews.map((item, i) => (
                        <div className='review-item' key={i}>
                          <div className='profile-image'>
                            <img src='/images/img-review-profile.webp' alt='' />
                          </div>
                          <div className='detail'>
                            <div className='name'>
                              {item.title.rendered} 
                              <span style={{ marginLeft: 5 }}className={`fi fi-${item.acf.country.toLowerCase()}`}></span>
                              {/* {getUnicodeFlagIcon(item.acf.country)} */}
                            </div>
                            <div className='rating'>
                              <Rating edit={false} value={item.acf.rate} />
                            </div>
                            <div className='message'>{item.acf.message}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    'No Review'
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Style>

      <Modal name='create-review-success' width='600px'>
        <Translate
          en='Thank you for sharing your experience with BENAS'
          th='ขอบคุณที่ร่วมแชร์ ประสบการณ์ของคุณกับ BENAS'
        />
      </Modal>
    </>
  )
}

const Style = styled('div')`
  label: FAQ;

  position: relative;

  h1 {
    ${fluidSizing('font-size', { SM: 20, M: 20, T: 35, D: 45 })}
    font-family: 'aller_bold', 'Kanit', sans-serif;
  }

  p {
    ${fluidSizing('font-size', { SM: 11, M: 11, T: 19, D: 19 })}
  }

  .box-faq {
    background: #fff url('/images/img-banner-faq.webp') center center no-repeat;
    background-size: cover;
    height: 100vh;
    display: flex;

    .image {
      background: #fff url('/images/img-banner-faq-left.webp') center center no-repeat;
      background-size: cover;
      width: 50%;
    }

    .faq-list {
      width: 50%;
      padding: 100px 40px;
      overflow: auto;
    }
  }

  .content {
    color: #4b0585;
    position: relative;
    padding: 60px 20px;
    background: #fff url('/images/img-bg-faq.webp') center center no-repeat;
    background-size: cover;
    font-family: 'Kanit';
    font-weight: 300;

    ${paddingFooter}
  }

  .form {
    margin-top: 40px;
  }

  .rating {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }

  .error-message {
    color: #ff0000;
    font-size: 11px;
  }

  .field-item {
    display: flex;
    margin-bottom: 15px;

    .label {
      margin-right: 20px;
      flex-shrink: 0;
      ${fluidSizing('width', { SM: 70, M: 70, T: 150, D: 150 })}
      ${fluidSizing('font-size', { SM: 11, M: 11, T: 19, D: 19 })}

      &.required::after {
        color: #ff0000;
        content: '*';
      }
    }

    .input {
      flex-grow: 1;
    }

    input,
    select {
      width: 100%;
      border: none;
      background: none;
      border-bottom: 1px solid #4b0585;
      outline: none;
      color: #4b0585;
      ${fluidSizing('font-size', { SM: 11, M: 11, T: 19, D: 19 })}
    }

    textarea {
      width: 100%;
      background: none;
      border: 1px solid #4b0585;
      outline: none;
      padding: 10px;
      border-radius: 10px;
      color: #4b0585;
      ${fluidSizing('font-size', { SM: 11, M: 11, T: 19, D: 19 })}
    }
  }

  .box-reviews {
    padding: 26px 39px;
    border-radius: 10px;
    background-color: rgb(255 255 255 / 20%);
  }

  .review-list {
    max-height: 540px;
    overflow: auto;
    padding-right: 17px;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: rgb(255 255 255 / 20%);
    }

    &::-webkit-scrollbar-thumb {
      background-color: #fff;
      border-radius: 100px;
    }
  }

  .review-item {
    display: flex;
    padding: 26px 32px;
    border-radius: 10px;
    background-color: rgb(255 255 255 / 20%);
    margin: 5px 0;

    .profile-image {
      width: 80px;
      height: 80px;
      margin-right: 18px;
      flex-shrink: 0;

      img {
        width: 100%;
      }
    }

    .name {
      font-weight: bold;
      ${fluidSizing('font-size', { SM: 11, M: 11, T: 20, D: 20 })}
    }

    .message {
      color: #666666;
      font-size: 16px;
      font-weight: 300;
      font-family: 'Kanit';
      margin-top: 10px;
      line-height: 1.17;
    }
  }

  ${breakpoint('M')} {
    .box-faq {
      height: auto;

      .image {
        display: none;
      }

      .faq-list {
        width: 100%;
        padding: 60px 20px;
      }
    }

    .content {
      position: relative;
      /* padding: 160px 20px; */
    }

    .box-reviews {
      padding: 15px;
    }

    .review-item {
      padding: 15px;

      .profile-image {
        width: 40px;
        height: 40px;
        margin-right: 10px;
      }
    }
  }
`

export default FAQ
