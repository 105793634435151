import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'

import Button from '../Button'
import { breakpoint } from '../../utils'

const Cookie = (props) => {
  const [accepted, setAccepted] = useState(true)

  useEffect(() => {
    const isAccepeted = window.localStorage.getItem('benas-accept-cookie') === 'yes'
    setAccepted(isAccepeted)
  }, [])

  const handleClickAccept = () => {
    window.localStorage.setItem('benas-accept-cookie', 'yes')
    setAccepted(true)
  }

  const handleClickReject = () => {
    setAccepted(true)
  }

  if (accepted) return null

  return (
    <Style>
      <div className='text'>
        WE USE COOKIES TO ENSURE THAT WE GIVE YOU THE BEST EXPERIENCE ON OUR WEBSITE.
        <br />
        <a href="/privacy-policy" target="_blank">
          <u>LEARN MORE ABOUT OUR PRIVACY POLICY</u>
        </a>
      </div>
      <div className='action-group'>
        <Button onClick={handleClickAccept}>ACCEPT</Button>
        <Button onClick={handleClickReject} background='none'>
          REJECT
        </Button>
      </div>
    </Style>
  )
}

export default Cookie

const Style = styled.div`
  label: Cookie;

  position: fixed;
  z-index: 99;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgb(0 0 0 / 80%);
  display: flex;
  justify-content: center;
  padding: 40px;

  .text {
    font-weight: 300;
    font-size: 12px;
    text-align: center;
    line-height: 1.67;
    margin-right: 60px;
  }

  ${breakpoint('M')} {
    display: block;
    text-align: center;

    .text {
      margin: 0;
      margin-bottom: 20px;
    }
  }

  a {
    text-decoration: none;
    color: #FFF;
  }
`
