import styled from '@emotion/styled'
import { useEffect, useRef, useState } from 'react'
import { gsap } from 'gsap'
import Translate from '../../components/Translate'
import { breakpoint, fluidSizing } from '../../utils'
import { paddingFooter } from '../../components/Footer'

const InsideAndInspiration = () => {
  const pageRef = useRef()
  const [contentActive, setContentActive] = useState('right')

  useEffect(() => {
    const page = pageRef.current
    setTimeout(() => {
      const animationSelectors = page.querySelectorAll('.animate')
      gsap.fromTo(
        animationSelectors,
        {
          opacity: 0,
          y: 60
        },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          stagger: 0.2
        }
      )
    }, 300)
  }, [])

  return (
    <Style ref={pageRef}>
      <div
        className='wrapper'
        style={{ transform: `translateX(${contentActive === 'right' ? '-60%' : '0%'})` }}
      >
        <div className='left'>
          <div className='inspiration-detail'>
            <h1>INSPIRATION</h1>
            <img src='/images/img-inspiration.webp' alt='' />
            <p>
              <Translate
                en='The purple color of the packaging is inspired by a variety of edamame with unique purple flowers. In addition, the shape of the packaging is inspired by the organic shape of edamame seed which is novel, distinctive and easy to grip.'
                th='สีสันของบรรจุภัณฑ์ได้แรงบันดาลใจจากดอกของถั่วแระญี่ปุ่นสายพันธุ์หนึ่งซึ่งมีดอกสีม่วง นอกจากนี้รูปทรงของบรรจุภัณฑ์ยังได้แรงบันดาลใจจากรูปทรงออร์แกนิกของเมล็ดถั่วแระซึ่งมีความแปลกใหม่ โดดเด่นและยังจับถนัดมืออีกด้วย'
              />
            </p>
          </div>
        </div>
        <div
          className='box-slider'
          style={{ backgroundColor: `${contentActive === 'right' ? '#7bb22b' : '#ffc00d'}` }}
        >
          {contentActive === 'left' && (
            <img
              className='prev'
              onClick={() => setContentActive('right')}
              src='/images/ico-arrow-slider-left.webp'
              alt=''
            />
          )}
          {contentActive === 'right' && (
            <img
              className='next'
              onClick={() => setContentActive('left')}
              src='/images/ico-arrow-slider-right.webp'
              alt=''
            />
          )}
          <img className='product' src='/images/ico-product-introduction.webp' alt='' />
        </div>
        <div className='right'>
          <div className='inside-detail'>
            <div className='mobile'>
              <h1>INSIDE BENAS</h1>
              <div className='row'>
                <div className='D-5'>
                  <img src='/images/img-edamame.webp' alt='' />
                </div>
                <div className='D-7'>
                  <Translate
                    en={
                      <>
                        <h3>Edamame</h3>
                        <p>
                          is classified as a Superfood and a rich source of protein. It is high in
                          nutritional value as it contains essential vitamins and minerals such as
                          vitamin A, vitamin B, vitamin C, vitamin K, calcium and iron. It is low in
                          fat while providing low energy but high in dietary fiber.
                        </p>
                      </>
                    }
                    th={
                      <>
                        <h3>ถั่วแระญี่ปุ่น</h3>
                        <p>
                          จัดเป็นซูเปอร์ฟู้ด (Superfood) เป็นแหล่งโปรตีน
                          มีคุณค่าทางอาหารสูงเนื่องจากมีวิตามินและแร่ธาตุที่จำเป็นต่อร่างกาย เช่น
                          วิตามิน A วิตามิน B วิตามิน C วิตามิน K  แคลเซียมและธาตุเหล็ก
                          ให้พลังงานและไขมันต่ำ ทั้งมีใยอาหารสูง
                        </p>
                      </>
                    }
                  />
                </div>
              </div>

              <div className='row'>
                <div className='D-7'>
                  <Translate
                    en={
                      <>
                        <h3>White Chocolate</h3>
                        <p>
                          put together with edamame and other ingredients become edamame spread with
                          white chocolate that is sweet, flavorful and unique.
                        </p>
                      </>
                    }
                    th={
                      <>
                        <h3>ไวท์ช็อกโกแลต</h3>
                        <p>
                          ปรุงผสมผสานกับถั่วแระญี่ปุ่นและส่วนผสมอื่นๆ
                          จนได้เป็นผลิตภัณฑ์ทาขนมปังถั่วแระญี่ปุ่นผสมไวท์ช็อกโกแลตที่หอมหวาน
                          อร่อยลงตัวและมีเอกลักษณ์
                        </p>
                      </>
                    }
                  />
                </div>
                <div className='D-5'>
                  <img src='/images/img-white-chocolate.webp' alt='' />
                </div>
              </div>

              <div className='row'>
                <div className='D-5'>
                  <img src='/images/img-rice-bran-oil.webp' alt='' />
                </div>
                <div className='D-7'>
                  <Translate
                    en={
                      <>
                        <h3>Rice Bran Oil</h3>
                        <p>
                          is a rich source of good fats that can lower blood cholesterol levels.
                          This reduces the risk of heart disease and diabetes. Additionally it is
                          rich in vitamins, minerals and important substances that are beneficial to
                          human health such as Gamma Oryzanol that has antioxidant properties.
                        </p>
                      </>
                    }
                    th={
                      <>
                        <h3>น้ำมันรำข้าว</h3>
                        <p>
                          แหล่งไขมันดีที่สามารถลดระดับคอเลสเตอรอลในเลือดได้
                          ซึ่งจะช่วยลดความเสี่ยงโรคหัวใจและโรคเบาหวาน อุดมไปด้วยวิตามิน แร่ธาตุต่างๆ
                          และสารสำคัญที่มีประโยชน์ต่อร่างกาย เช่น
                          สารแกมมาโอรีซานอลที่มีคุณสมบัติเป็นสารต้านอนุมูลอิสระ
                        </p>
                      </>
                    }
                  />
                </div>
              </div>
            </div>
            <div className='desktop'>
              <Translate
                en={<img src='/images/img-inside-beanas-en.webp' alt='' />}
                th={<img src='/images/img-inside-beanas-th.webp' alt='' />}
              />
            </div>
          </div>
        </div>
      </div>
    </Style>
  )
}

const Style = styled('div')`
  label: InsideAndInspiration;

  position: relative;
  /* height: 100vh; */
  min-height: 600px;
  /* background: rgb(123, 178, 44);
  background: linear-gradient(
    90deg,
    rgba(123, 178, 44, 1) 0%,
    rgba(123, 178, 44, 1) 40%,
    rgba(255, 193, 14, 1) 40%,
    rgba(255, 193, 14, 1) 100%
  ); */
  overflow: hidden;
  /* display: flex;
  align-items: stretch;
  justify-content: center; */

  .wrapper {
    display: flex;
    /* align-items: stretch; */
    /* justify-content: center; */
    position: relative;
    transition: all 0.3s ease-in-out;
  }

  .mobile {
    display: none;
  }

  .left {
    width: 60%;
    flex-shrink: 0;
    background: #7bb22b;
  }

  .right {
    width: 60%;
    flex-shrink: 0;
    background: #ffc00d;
    position: relative;
    display: flex;
    align-items: center;
  }

  .box-slider {
    width: 40%;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    background: #7bb22b;
    position: relative;
    transition: all 0.3s ease-in-out;

    img {
      width: 100%;
    }

    .prev {
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 20px;
      transform: translateY(-50%);
      width: 27px;
      cursor: pointer;
    }

    .next {
      position: absolute;
      z-index: 1;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      width: 27px;
      cursor: pointer;
    }
  }

  .inspiration-detail {
    position: relative;
    display: flex;
    align-items: center;
    padding: 100px 60px;
    color: #48267c;
    max-width: 900px;

    > h1 {
      position: absolute;
      top: 10%;

      ${fluidSizing('font-size', { SM: 20, M: 20, T: 35, D: 45 })}
    }

    > p {
      position: absolute;
      bottom: 10%;
      left: 0;
      right: 0;
      padding: 0 60px;
      text-align: center;
      font-family: 'Kanit';
      font-weight: 300;
    }

    img {
      width: 100%;
    }
  }

  .inside-detail {
    padding: 60px 0;
    color: #4b0585;
    font-family: 'Kanit';

    img {
      width: 100%;
      max-width: 1000px;
    }

    h3 {
      position: relative;
      margin-bottom: 10px;

      &:before {
        content: '+';
        position: absolute;
        color: #fff;
        top: 0px;
        left: -15px;
      }
    }

    p {
      margin-top: 0;
      font-weight: 300;
      font-size: 12px;
      line-height: 1.2;
    }
  }

  ${breakpoint('M')} {
    background: #ffc00d;

    .wrapper {
      transform: translateX(0%) !important;
      flex-wrap: wrap;
    }

    .box-slider {
      display: none;
    }

    .left,
    .right {
      width: 100%;
    }

    .inspiration-detail {
      padding: 60px 20px;
      display: block;

      > p {
        position: static;
        padding: 0;
      }
    }

    .inside-detail {
      padding: 60px 20px;
      display: block;
    }

    .desktop {
      display: none;
    }

    .mobile {
      display: block;
    }
  }

  ${paddingFooter}
`

export default InsideAndInspiration
