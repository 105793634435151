import styled from '@emotion/styled'
import { useRef } from 'react'
import { gsap } from 'gsap'
// import { useMediaQuery } from 'react-responsive'

export const openModal = (name) => {
  gsap.to(`.${name}`, 0.3, {
    autoAlpha: 1,
    display: 'flex',
    ease: 'ease-in-out'
  })

  gsap.to(`.${name} .overlay`, 0.3, {
    autoAlpha: 0.3,
    display: 'block',
    delay: 0.15,
    ease: 'ease-in-out'
  })

  gsap.to(`.${name} .modal-cotent`, 0.3, {
    autoAlpha: 1,
    y: '0',
    delay: 0.3,
    ease: 'ease-in-out'
  })
}

export const closeModal = (name) => {
  gsap.to(`.${name}`, 0.3, {
    autoAlpha: 0,
    display: 'none',
    delay: 0.6,
    ease: 'ease-in-out'
  })

  gsap.to(`.${name} .overlay`, 0.3, {
    autoAlpha: 0,
    display: 'none',
    delay: 0.3,
    ease: 'ease-in-out'
  })

  gsap.to(`.${name} .modal-cotent`, 0.3, {
    autoAlpha: 0,
    y: '20px',
    ease: 'ease-in-out'
  })
}

function Modal({ name, width, hideCloseButton, noPadding, children }) {
  const ref = useRef()
  const overlayRef = useRef()

  return (
    <>
      <Style ref={ref} className={`modal ${name}`} width={width} noPadding={noPadding}>
        <div className='modal-cotent'>
          {!hideCloseButton && (
            <div className='close' onClick={() => closeModal(name)}>
              <img src='/images/ico-close-modal.webp' alt='idesign' width='21' height='21' />
            </div>
          )}
          {children}
        </div>
        <div ref={overlayRef} className='overlay' onClick={() => closeModal(name)}></div>
      </Style>
    </>
  )
}

export default Modal

const Style = styled('div')`
  label: Modal;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  opacity: 0;
  display: none;
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-cotent {
    position: relative;
    z-index: 99;
    width: ${(p) => (p.width ? p.width : 'auto')};
    border-radius: 15px;
    background-color: #fff;
    background: #fff url('/images/img-bg-modal-violet.webp') center center no-repeat;
    background-size: cover;
    transform: translate3d(0, 20%, 0);
    margin: 20px;
    opacity: 0;
    will-change: auto;
    padding: ${(p) => (p.noPadding ? '0' : '88px')};
    text-align: center;
    font-size: 20px;
    line-height: 1.5;

    > .close {
      position: absolute;
      cursor: pointer;
      right: 0;
      top: 0;
      padding: 40px;
    }
  }

  @media (max-width: 758px) {
    .modal-cotent {
      padding: ${(p) => (p.noPadding ? '0' : '40px')};

      .close {
        padding: 20px;
      }
    }
  }
`
