import styled from '@emotion/styled'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import Slider from 'react-slick'
import Select from 'react-select'
import { useState, useRef, useEffect } from 'react'
import { gsap } from 'gsap'

import { paddingFooter } from '../components/Footer'
import Translate from '../components/Translate'

import { breakpoint } from '../utils'
import { useTranslation } from 'react-i18next'

const selectShopPlaceHolder = {
  en: "Select Branch",
  th: "เลือกสาขา"
}

const settings = {
  dots: false,
  arrows: false,
  fade: false,
  autoplay: false,
  infinite: true,
  speed: 500,
  autoplaySpeed: 4000,
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: []
}

const WhereToBuy = () => {
  const sliderRef = useRef()
  const pageRef = useRef()
  const [selectedBrand, setSelectedBrand] = useState(null)
  const [selectedShop, setSelectedShop] = useState(null)
  const { i18n } = useTranslation()
  const appData = useSelector((state) => state.data)

  useEffect(() => {
    setTimeout(() => {
      const animationSelectors = pageRef.current.querySelectorAll('.animate')

      gsap.fromTo(
        animationSelectors,
        {
          opacity: 0,
          y: 40
        },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          stagger: 0.2
        }
      )
    }, 100)
  }, [])

  // if (isEmpty(appData.whereToBuy)) return null

  const { whereToBuy } = appData

  const languageChangedFactor = get(whereToBuy, "0.shopList.0.address", null)

  useEffect(() => {
    if (selectedBrand) {
      const latestSelectedBrand = whereToBuy.find(({ brandName }) => brandName === selectedBrand.brandName)
      if (latestSelectedBrand) {
        handleSelectBrand(latestSelectedBrand)
        if (selectedShop) {
          const latestSelectedShop = latestSelectedBrand.shopList.find(({ lat }) => selectedShop.lat === lat)
          setSelectedShop(latestSelectedShop)
        }
      }
    } else if (whereToBuy.length > 0) {
      handleSelectBrand(whereToBuy[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languageChangedFactor, whereToBuy.length, i18n.language])

  useEffect(() => {
    if (selectedBrand && selectedBrand.shopList && selectedBrand.shopList[0]) {
      setSelectedShop(selectedBrand.shopList[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBrand])

  const handleSelectBrand = (brand) => {
    const isExisting = () => {
      if (isEmpty(selectedBrand)) return false
      if (selectedBrand.brandName === brand.brandName) return true
      return false
    }

    if (isExisting()) return

    setSelectedBrand(brand)
    setSelectedShop(brand.shopList[0])
  }

  const handleSelectShop = ({ value }) => {
    if (isEmpty(selectedBrand)) return
    const shop = selectedBrand.shopList.find((shop) => shop.name === value)
    if (!shop) {
      setSelectedShop(null)
      return
    }
    setSelectedShop(shop)
  }

  const getActiveClass = (brand) => {
    if (isEmpty(selectedBrand)) return ''
    if (brand.brandName === selectedBrand.brandName) return 'active'
    return ''
  }

  const hasLatLong = () => {
    if (isEmpty(selectedShop)) return false
    if (typeof selectedShop.lat !== 'number' && isEmpty(selectedShop.lat)) return false
    if (typeof selectedShop.long !== 'number' && isEmpty(selectedShop.long)) return false
    return true
  }

  const goToGoogleMap = () => {
    const location = `${selectedShop.lat},${selectedShop.long}`
    window.open(`https://www.google.com/maps?saddr=Current+Location&daddr=${location}`)
  }

  const opacity = (item) => {
    if (item.active) return '1'
    return '0.5'
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: '#000',
      width: 300
    })
  }

  console.log("selectedShop", selectedShop)

  return (
    <>
      <Helmet>
        <title>Where to buy</title>
        <meta name='title' content='Where to buy' />
        <meta name='description' content='' />
      </Helmet>

      <Style>
        <div className='wrapper' ref={pageRef}>
          <div className='container'>
            <div className='row'>
              <div className='D-3 M-12 vertical-middle'>
                <h1 className='animate'>Where to buy</h1>
              </div>
              <div className='D-9 M-12 vertical-middle'>
                <div className='box-slider animate'>
                  <img
                    className='prev'
                    onClick={() => sliderRef.current.slickPrev()}
                    src='/images/ico-arrow-slider-left.webp'
                    alt=''
                  />
                  <img
                    className='next'
                    onClick={() => sliderRef.current.slickNext()}
                    src='/images/ico-arrow-slider-right.webp'
                    alt=''
                  />
                  <Slider ref={sliderRef} {...settings}>
                    {whereToBuy.map((item, i) => (
                      <div
                        key={i}
                        className={`slider-item ${getActiveClass(item)}`}
                        onClick={() => handleSelectBrand(item)}
                      >
                        <img key={i} src={item.image} alt='' style={{ opacity: opacity(item) }} />
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
            <div className='brand-detail'>
              <div className='row'>
                <div className='D-5 M-12'>
                  {selectedBrand && selectedBrand.active && selectedBrand.shopList && selectedBrand.shopList.length > 0 ? (
                    <div className='shop-selector'>
                      <div className='label'>สาขา</div>
                      <Select
                        name='shop'
                        id='shop'
                        className="react-select"
                        styles={customStyles}
                        value={{
                          value: selectedShop.name,
                          label: selectedShop.name
                        }}
                        placeholder={selectShopPlaceHolder[i18n.language]}
                        options={selectedBrand.shopList.map((shop, i) => ({
                          value: shop.name,
                          label: shop.name
                        }))}
                        onChange={handleSelectShop}
                      />
                      {/* <select
                        name='shop'
                        id='shop'
                        value={get(selectedShop, 'name', 'empty')}
                        onChange={handleSelectShop}
                      >
                        <option value='empty'>เลือกสาขา</option>
                        {selectedBrand.shopList.map((shop, i) => (
                          <option key={i} value={shop.name}>
                            {shop.name}
                          </option>
                        ))}
                      </select> */}
                    </div>
                  ) : (
                    <div className='coming-soon'>
                      <h2>
                        <Translate
                          en='Coming Soon'
                          th='พบกันใหม่เร็ว ๆ นี้'
                        />
                      </h2>
                    </div>
                  )}
                </div>
                <div className='D-1 M-12'></div>
                <div className='D-4 M-12 animate'>
                  {selectedShop && selectedBrand.active ? (
                    <div className='shop-address'>
                      <h4>
                        {selectedBrand.brandName} ({selectedShop.name})
                      </h4>
                      <p>
                        {selectedShop.address} <br />
                        โทร: {selectedShop.phone} <br />
                        เวลาทำการ: {selectedShop.workingHours}
                      </p>
                      {hasLatLong() && (
                        <img
                          src='/images/ico-google-map.webp'
                          alt=''
                          className='icon-google-map'
                          onClick={goToGoogleMap}
                        />
                      )}
                      <img
                        src='/images/img-produce-where-to-buy.webp'
                        alt=''
                        className='product-image'
                      />
                    </div>
                  ) : <div />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Style>
    </>
  )
}

const Style = styled('div')`
  label: WhereToBuy;

  .react-select {
    width: 300px;
  }

  position: relative;
  min-height: 100vh;
  background: #fff url('/images/img-where-to-buy-page.webp') center center no-repeat;
  background-size: cover;

  .wrapper {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    min-height: 100vh;
    padding: 232px 20px;
  }

  h1 {
    font-size: 44px;
    width: 150px;
    text-transform: uppercase;
  }

  .coming-soon {
    text-align: center;
  }

  .box-slider {
    position: relative;
    margin: 0 60px;

    .prev {
      position: absolute;
      top: 50%;
      left: -40px;
      transform: translateY(-50%);
      width: 27px;
      cursor: pointer;
    }

    .next {
      position: absolute;
      top: 50%;
      right: -40px;
      transform: translateY(-50%);
      width: 27px;
      cursor: pointer;
    }
  }

  .slider-item {
    padding: 30px;
    /* transform: scale(1.4); */
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    &.active {
      transform: scale(1.4);
    }

    img {
      width: 100%;
    }
  }

  .brand-detail {
    margin-top: 60px;
  }

  .shop-selector {
    display: flex;
    align-items: center;

    .label {
      margin-right: 20px;
    }

    select {
      flex-grow: 1;
      background: none;
      border: none;
      outline: none;
      color: #fff;
    }
  }

  .shop-address {
    position: relative;
    font-family: 'Kanit';
    font-weight: 300;
    line-height: 1.58;

    h4 {
      margin-top: 0;
      padding-bottom: 10px;
      border-bottom: 1px solid #fff;
    }

    .icon-google-map {
      cursor: pointer;
      width: 30px;
    }
  }

  .product-image {
    position: absolute;
    width: 260px;
    top: 20px;
    right: -60%;
  }

  ${breakpoint('M')} {
    background: #fff url('/images/img-where-to-buy-page-mobile.webp') center center no-repeat;
    background-size: cover;

    .wrapper {
      padding-top: 66px;
      padding-bottom: 66px;
    }

    h1 {
      font-size: 20px;
      width: 80px;
      line-height: 1.2;
    }

    .shop-address {
      width: 100%;
      max-width: 280px;
      margin: 40px auto;
    }

    .box-slider {
      margin: 0;
    }

    .prev,
    .next {
      display: none;
    }

    .slider-item {
      padding: 20px;
      transform: scale(0.9);
      &.active {
        transform: scale(1.2);
      }
    }

    .shop-selector {
      width: 100%;
      max-width: 280px;
      margin: 0 auto;
    }

    .product-image {
      width: 140px;
      top: 90px;
      right: -10%;
    }
  }

  ${breakpoint('SM')} {
    .slider-item {
      padding: 10px;
    }
  }

  ${paddingFooter}
`

export default WhereToBuy
