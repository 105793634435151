import styled from '@emotion/styled'
import { useEffect, useRef } from 'react'
import { gsap } from 'gsap'
import Translate from '../../components/Translate'
import { breakpoint, fluidSizing } from '../../utils'

const TopBanner = () => {
  const pageRef = useRef()

  useEffect(() => {
    const page = pageRef.current
    setTimeout(() => {
      const animationSelectors = page.querySelectorAll('.animate')
      gsap.fromTo(
        animationSelectors,
        {
          opacity: 0,
          y: 60
        },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          stagger: 0.2
        }
      )
    }, 300)
  }, [])

  return (
    <Style ref={pageRef}>
      <div className='container'>
        <div className='row'>
          <div className='D-7 M-12 D-order-2 M-order-1 vertical-middle'>
            <img
              className='product-image animate'
              src='/images/img-product-banner-about.webp'
              alt=''
            />
          </div>
          <div className='D-1'></div>
          <div className='D-4 M-12 D-order-1 M-order-2 vertical-middle'>
            <div className='caption animate'>
              <h1>
                OUR PRODUCT <br />
                “BENAS”
              </h1>
              <p>
                <Translate
                  en='It originally comes from the word "BEANS." Then, these 5 letters are re-arranged and become a trademark "BENAS". Three symbolic edamame seeds are put together as an icon on top of the brand name.'
                  th='มาจากคำว่า “BEANS” ที่แปลว่า ”ถั่ว” โดยนำมาเรียงตัวอักษรใหม่ กลายเป็นเครื่องหมายการค้า “BENAS” (บีนาส) และมีการนำรูปทรงของเมล็ดถั่วแระ 3 เม็ด มาเรียงต่อกันเป็นไอคอนในชื่อแบรนด์'
                />
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <div className='bottom'>
        <img src='/images/ico-arrow-down.webp' alt='' className='arrow-down' />
      </div> */}
    </Style>
  )
}

const Style = styled('div')`
  label: TopBanner;

  position: relative;
  height: 100vh;
  min-height: 600px;
  background: #fff url('/images/img-banner-about.webp') center center no-repeat;
  background-size: cover;
  overflow: hidden;
  padding: 140px 20px;
  display: flex;
  align-items: center;

  img.product-image {
    width: 100%;
    max-width: 857px;
    /* margin-bottom: -200px; */
  }

  .caption {
    /* margin-top: -150px; */

    > h1 {
      ${fluidSizing('font-size', { SM: 20, M: 20, T: 35, D: 45 })}
    }

    > p {
      font-family: 'Kanit';
      font-weight: 300;
      line-height: 1.4;
      ${fluidSizing('font-size', { SM: 14, M: 14, T: 19, D: 19 })}
    }
  }

  .bottom {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    font-size: 11px;
    padding: 18px;
  }

  .arrow-down {
    width: 22px;
    margin-bottom: 20px;
  }

  ${breakpoint('M')} {
    img.product-image {
      max-width: 375px;
      display: block;
      margin: 0 auto;
    }
    .caption {
      margin-top: 50px;
    }
  }
`

export default TopBanner
