import styled from '@emotion/styled'
import { paddingFooter } from '../components/Footer'

// import image from '../images/welcome.jpg'
import { fluidSizing } from '../utils'

const PageNotFound = () => {
  return (
    <Style>
      <div className='content'>
        <div>404 Page not found</div>
      </div>
    </Style>
  )
}

const Style = styled('div')`
  label: PageNotFound;

  background-size: cover;
  background: #fff url('/images/img-banner-about.webp') center center no-repeat;

  .content {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    text-align: center;

    ${fluidSizing('font-size', { SM: 20, M: 20, T: 60, D: 60 })}
  }

  ${paddingFooter}
`

export default PageNotFound
