import PropTypes from 'prop-types'
import React from 'react'
import ReactStars from 'react-rating-stars-component'

const Rating = (props) => {
  return (
    <ReactStars
      {...{
        edit: props.edit,
        count: 5,
        value: props.value,
        emptyIcon: <img src='/images/ico-star-form-normal.webp' alt='' width='20' height='19' />,
        filledIcon: <img src='/images/ico-star-form-active.webp' alt='' width='20' height='19' />,
        onChange: props.onChange
      }}
    />
  )
}

Rating.propTypes = {
  edit: PropTypes.bool.isRequired,
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired
}

Rating.defaultProps = {
  edit: true,
  value: 0,
  onChange: () => null
}

export default Rating
