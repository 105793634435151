import styled from '@emotion/styled'
import { Helmet } from 'react-helmet'
import { paddingFooter } from '../components/Footer'
import { breakpoint, fetchHandler, history } from '../utils'
import { fetchNews } from '../api/fetchers'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

const NewsDetail = () => {
  // const appData = useSelector((state) => state.data)

  // if (isEmpty(appData.content)) return null

  // const { content } = appData
  const pageRef = useRef()
  const { slug } = useParams()
  const { i18n } = useTranslation()
  const [newsDetail, setNewsDetail] = useState({})

  useEffect(() => {
    const callAction = async () => {
      const params = {
        slug,
        lang: i18n.language
      }
      const response = await fetchNews(params)

      if (isEmpty(response.data)) {
        history.push('/404')
        return
      }

      setNewsDetail(response.data[0])
    }

    fetchHandler({
      callAction
    })
  }, [slug, i18n.language])

  console.log('detail', newsDetail)

  return (
    <>
      <Helmet>
        <title>{get(newsDetail, 'title.rendered')}</title>
        <meta name='title' content={get(newsDetail, 'title.rendered')} />
        <meta name='description' content='' />
      </Helmet>

      <Style ref={pageRef}>
        <div className='banner'></div>
        <div className='container'>
          <h1>{get(newsDetail, 'title.rendered')}</h1>
          <div dangerouslySetInnerHTML={{ __html: get(newsDetail, 'acf.detail') }}></div>
        </div>
      </Style>
    </>
  )
}

const Style = styled('div')`
  label: NewsDetail;

  background: #fff;
  color: #482c89;
  font-family: 'Kanit', sans-serif;

  img {
    max-width: 100%;
    height: auto;
  }

  .container {
    padding-bottom: 60px;
  }

  .banner {
    height: 520px;
    background: #fff url('/images/img-banner-news-detail.webp') center bottom no-repeat;
    background-size: cover;
    margin-bottom: 60px;
  }

  ${breakpoint('M')} {
    .banner {
      height: 290px;
    }
  }

  ${paddingFooter}
`

export default NewsDetail
