import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'
import { fluidSizing } from '../../utils'

const Follow = (props) => {
  const goto = (url) => {
    window.open(url)
  }

  return (
    <Style className='follow' titleColor={props.titleColor}>
      <h4>Follow the taste</h4>
      <img
        onClick={() => goto('https://www.facebook.com/Benas.Spread')}
        src='/images/ico-fb.webp'
        alt=''
      />
      <img
        onClick={() => goto('https://instagram.com/benas.spread')}
        src='/images/ico-ig.webp'
        alt=''
      />
      {/* <img src='/images/ico-line.webp' alt='' /> */}
    </Style>
  )
}

Follow.propTypes = {
  titleColor: PropTypes.string
}

Follow.defaultProps = {
  titleColor: '#4b0585'
}

export default Follow

const Style = styled.div`
  label: Follow;

  display: inline-block;

  > h4 {
    color: ${(p) => p.titleColor};
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 13px;
    margin-top: 0;
    ${fluidSizing('font-size', { SM: 13, M: 13, D: 16 })}
    ${fluidSizing('margin-bottom', { SM: 10, M: 10, D: 13 })}
  }

  img {
    ${fluidSizing('height', { SM: 23, M: 23, D: 33 })}
    cursor: pointer;

    &:not(:last-of-type) {
      margin-right: 18px;
    }
  }
`
