import { Helmet } from 'react-helmet'
import styled from '@emotion/styled'
import isEmpty from 'lodash/isEmpty'

import { breakpoint, fluidSizing } from '../utils'
import { paddingFooter } from '../components/Footer'
import { useSelector } from 'react-redux'
import MenuSlider from './Home/MenuSlider'

const Menu = () => {
  const appData = useSelector((state) => state.data)

  if (isEmpty(appData.menus)) return null

  const { menus } = appData

  return (
    <>
      <Helmet>
        <title>Menu</title>
        <meta name='title' content='Menu' />
        <meta name='description' content='' />
      </Helmet>

      <Style>
        <MenuSlider />
        <div className='box-inspired-of-taste'>
          <div className='container'>
            <h1>Inspired Taste</h1>
            {/* <div className='iframe-container'>
              <iframe
                title='vdo'
                className='responsive-iframe'
                src='https://www.youtube.com/embed/tgbNymZ7vqY'
              ></iframe>
            </div> */}
            <br />
            <div className='promotion-list'>
              <div className='row'>
                {menus.map((item, i) => (
                  <div key={i} className='D-3 M-6'>
                    <div className='item-promotion-slide'>
                      <div className='promotion-card'>
                        <div className='image'>
                          <img src={item.acf.featured_image} alt='' />
                          <img src='/images/img-arc-item.webp' alt='' className='arc' />
                        </div>
                        <div className='detail'>
                          <h3>
                            <span>+</span> {item.title.rendered}
                          </h3>
                          <br />
                          {/* <p>{item.acf.short_description}</p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Style>
    </>
  )
}

const Style = styled('div')`
  label: Menu;

  .iframe-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
  }

  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;

    iframe {
      border: none;
    }
  }

  .box-inspired-of-taste {
    padding-top: 64px;
    background-image: linear-gradient(to bottom, #f7e9eb 1%, #b1def0 86%);

    h1 {
      margin-top: 0;
      text-align: center;
      text-transform: uppercase;
      ${fluidSizing('font-size', { SM: 20, M: 20, T: 35, D: 45 })}
      color: #482c89;
    }

    ${paddingFooter}
  }

  .promotion-list {
    padding-bottom: 60px;
  }

  .item-promotion-slide {
    padding: 15px 0;
  }

  .promotion-card {
    border-radius: 30px;
    overflow: hidden;
    background: #e1dfd9;

    > .image {
      position: relative;

      img {
        width: 100%;
      }

      .arc {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
      }
    }

    > .detail {
      padding: 0 10px 10px;
      font-family: 'Kanit';
      color: #482c89;

      h3 {
        ${fluidSizing('font-size', { SM: 14, M: 14, T: 18, D: 23 })}
        margin-top: 0;
        margin-bottom: 0;

        > span {
          color: #993486;
        }
      }

      p {
        margin-top: 5px;
        font-weight: 300;
        line-height: 1.2;
        max-height: 100px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        ${fluidSizing('font-size', { SM: 14, M: 14, T: 19, D: 19 })}
      }
    }
  }

  ${breakpoint('T')} {
  }

  ${breakpoint('M')} {
    .box-promotion {
      padding: 60px 20px;
    }
  }
`

export default Menu
