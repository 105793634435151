const getMessage = (res, options) => {
  return `Service error | ${options.url}`
}

const createErrorMessage = (res, options) => {
  throw Object({
    type: 'ERROR',
    message: getMessage(res, options)
  })
}

export default createErrorMessage
