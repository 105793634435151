import { createModule } from 'redux-modux'

const initialState = {
  content: {},
  whereToBuy: [],
  reviews: [],
  news: [],
  menuBanner: [],
  menus: []
}

const handlers = {}

export default createModule({ moduleName: 'data', initialState, handlers })
