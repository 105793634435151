import { Helmet } from 'react-helmet'
import { useRef } from 'react'
import { useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import Slider from 'react-slick'
import styled from '@emotion/styled'

import { breakpoint, fluidSizing, history } from '../utils'
import NewsAndPromotionSlider from './Home/NewsAndPromotionSlider'

const settings = {
  dots: true,
  arrows: false,
  fade: false,
  autoplay: false,
  infinite: true,
  speed: 500,
  autoplaySpeed: 4000,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: []
}

const NewsAndPromotion = () => {
  const sliderRef = useRef()

  const appData = useSelector((state) => state.data)

  if (isEmpty(appData.news)) return null

  const { news } = appData

  const handleClickNews = (news) => {
    history.push(`/news-promotion/${news.slug}`)
  }

  const pinedItems = news.filter((item) => item.acf.pin)

  return (
    <>
      <Helmet>
        <title>News & Promotion</title>
        <meta name='title' content='News & Promotion' />
        <meta name='description' content='' />
      </Helmet>

      <Style>
        <div className='box-banner'>
          <img src='/images/img-product-banner-news.webp' alt='' className='image-left-desktop' />
          <img src='/images/img-bean-news.webp' alt='' className='bean' />
          <div className='container'>
            <div className='slider'>
              <img
                className='prev'
                onClick={() => sliderRef.current.slickPrev()}
                src='/images/ico-arrow-slider-left.webp'
                alt=''
              />
              <img
                className='next'
                onClick={() => sliderRef.current.slickNext()}
                src='/images/ico-arrow-slider-right.webp'
                alt=''
              />

              <Slider ref={sliderRef} {...settings}>
                {pinedItems.map((item, i) => (
                  <div key={i}>
                    <div className='news-item-slide' onClick={() => handleClickNews(item)}>
                      <img src={item.acf.featured_image} alt='' />
                      <div className='caption'>
                        <h2>NEWS</h2>
                        <h3>
                          <span>+</span> {item.title.rendered}
                        </h3>
                        <p>{item.acf.short_description}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
        <NewsAndPromotionSlider />
      </Style>
    </>
  )
}

const Style = styled('div')`
  label: NewsAndPromotion;

  .box-banner {
    background: #000 url('/images/img-banner-news.webp') center center no-repeat;
    background-size: cover;
    height: 100vh;
    position: relative;
    display: flex;
    align-items: center;

    .image-left-desktop {
      position: absolute;
      top: 0;
      right: 0;
      ${fluidSizing('width', { SM: 200, M: 200, T: 350, D: 450 })}
    }

    .bean {
      position: absolute;
      bottom: -100px;
      width: 174px;
      right: 30%;
    }
  }

  .slider {
    position: relative;
    width: 100%;
    padding: 90px;

    .prev {
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 20px;
      transform: translateY(-50%);
      width: 27px;
      cursor: pointer;
    }

    .next {
      position: absolute;
      z-index: 1;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      width: 27px;
      cursor: pointer;
    }
  }

  .slick-dots {
    bottom: -40px;
  }

  .news-item-slide {
    display: flex;
    color: #482c89;
    font-family: 'Kanit', sans-serif;
    cursor: pointer;

    img {
      width: 40%;
      max-width: 400px;
      margin-right: 30px;
    }

    .caption {
      width: 30%;
    }

    h2 {
      ${fluidSizing('font-size', { SM: 20, M: 20, T: 35, D: 45 })}
      margin-top: 40px;
      margin-bottom: 10px;
    }

    h3 {
      ${fluidSizing('font-size', { SM: 14, M: 14, T: 18, D: 23 })}
      margin-top: 0;
      margin-bottom: 15px;

      > span {
        color: #993486;
      }
    }

    p {
      font-weight: 300;
      line-height: 1.2;
      font-size: 16px;
    }
  }

  ${breakpoint('T')} {
  }

  ${breakpoint('M')} {
    .news-item-slide {
      flex-direction: column;
      align-items: center;
      padding: 40px;

      img {
        width: 100%;
        display: block;
        margin: 0 auto;
      }

      .caption {
        width: 100%;
        max-width: 400px;
      }
    }

    .box-banner {
      .bean {
        bottom: -50px;
        width: 95px;
        right: 10%;
      }
    }

    .image-left-desktop {
      display: none;
    }

    .slider {
      padding: 20px;

      .prev,
      .next {
        display: none;
      }
    }
  }
`

export default NewsAndPromotion
