import createFetcher from './createFetcher'

const host = process.env.REACT_APP_CONTENT_HOST || ''
const useMock = false

const fetchContent = (params) =>
  createFetcher({
    useMock,
    method: 'get',
    url: `${host}/wp-json/acf/v3/options/options`,
    params,
    jsonMock: `content-${params.lang}.json`,
    delay: 0
  })

const fetchWhereToBuy = (params) =>
  createFetcher({
    useMock: true,
    method: 'get',
    url: `${host}/wp-json/wp/v2/where_to_buy`,
    params,
    jsonMock: `whereToBuy-${params.lang}.json`,
    delay: 0
  })

const fetchNews = (params) =>
  createFetcher({
    useMock,
    method: 'get',
    url: `${host}/wp-json/wp/v2/news`,
    params,
    jsonMock: `news-${params.lang}.json`,
    delay: 0
  })

const fetchSendMail = (body) =>
  createFetcher({
    useMock,
    method: 'post',
    url: `${host}/wp-admin/admin-ajax.php`,
    data: body,
    jsonMock: 'reviews.json',
    delay: 0
  })

const fetchCreateReview = (data) =>
  createFetcher({
    useMock,
    method: 'post',
    headers: {
      authorization: `Basic ${process.env.REACT_APP_BASIC_AUTH}`
    },
    url: `${host}/wp-json/wp/v2/reviews`,
    data,
    jsonMock: 'reviews.json',
    delay: 0
  })

const fetchReviews = (params) =>
  createFetcher({
    useMock,
    method: 'get',
    url: `${host}/wp-json/wp/v2/reviews`,
    params,
    jsonMock: 'reviews.json',
    delay: 0
  })

const fetchMenuBanner = (params) =>
  createFetcher({
    useMock: true,
    method: 'get',
    // url: `${host}/wp-json/wp/v2/reviews`,
    params,
    jsonMock: 'menu-banner.json',
    delay: 0
  })

const fetchMenus = (params) =>
  createFetcher({
    useMock,
    method: 'get',
    url: `${host}/wp-json/wp/v2/menus`,
    params,
    jsonMock: `menus-${params.lang}.json`,
    delay: 0
  })

export {
  fetchContent,
  fetchWhereToBuy,
  fetchNews,
  fetchSendMail,
  fetchCreateReview,
  fetchReviews,
  fetchMenuBanner,
  fetchMenus
}
