import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const Translate = (props) => {
  const { i18n } = useTranslation()

  return props[i18n.language]
}

Translate.propTypes = {
  en: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  th: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
}

Translate.defaultProps = {
  en: 'Test',
  th: 'ทดสอบ'
}

export default Translate
