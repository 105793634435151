import styled from '@emotion/styled'
import { useEffect, useRef } from 'react'
import { breakpoint, fluidSizing } from '../../utils'
import Slider from 'react-slick'
import { useSelector } from 'react-redux'
import { gsap } from 'gsap'

const settings = {
  dots: true,
  arrows: false,
  fade: false,
  autoplay: false,
  infinite: true,
  speed: 500,
  autoplaySpeed: 4000,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: []
}

const Menu = (props) => {
  const sliderRef = useRef()
  const pageRef = useRef()

  const appData = useSelector((state) => state.data)

  useEffect(() => {
    const page = pageRef.current
    setTimeout(() => {
      const animationSelectors = page.querySelectorAll('.animate')
      gsap.fromTo(
        animationSelectors,
        {
          opacity: 0,
          y: 120
        },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          stagger: 0.2,
          scrollTrigger: {
            trigger: page,
            start: 'top bottom-=200',
            once: true
          }
        }
      )
    }, 300)
  }, [])

  const { menuBanner } = appData

  return (
    <Style ref={pageRef}>
      <div className='box-banner'>
        <div className='slider'>
          <img
            className='prev'
            onClick={() => sliderRef.current.slickPrev()}
            src='/images/ico-arrow-slider-left.webp'
            alt=''
          />
          <img
            className='next'
            onClick={() => sliderRef.current.slickNext()}
            src='/images/ico-arrow-slider-right.webp'
            alt=''
          />

          <Slider ref={sliderRef} {...settings}>
            {menuBanner.map((item, i) => (
              <div key={i}>
                <div className='menu-item-slide'>
                  <div className='desktop' style={{ backgroundImage: `url(${item.image})` }}></div>
                  <div
                    className='mobile'
                    style={{ backgroundImage: `url(${item['image-mobile']})` }}
                  ></div>
                  <div className='caption'>
                    <h2>MENU Inspiration</h2>
                    <p>{item.caption}</p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </Style>
  )
}

const Style = styled('div')`
  label: Menu;

  position: relative;
  z-index: 1;

  .box-banner {
    position: relative;
    display: flex;
    align-items: center;
  }

  .slider {
    position: relative;
    width: 100%;

    .prev {
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 20px;
      transform: translateY(-50%);
      width: 27px;
      cursor: pointer;
    }

    .next {
      position: absolute;
      z-index: 1;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      width: 27px;
      cursor: pointer;
    }
  }

  .slick-dots {
    bottom: 40px;
  }

  .menu-item-slide {
    position: relative;
    display: flex;
    height: 100vh;
    width: 100%;
    color: #482c89;
    font-family: 'Kanit', sans-serif;

    .desktop,
    .mobile {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .mobile {
      display: none;
    }

    .caption {
      position: relative;
      z-index: 2;
      margin: 180px auto 0 30%;
      width: 100%;
      max-width: 450px;
    }

    h2 {
      font-family: 'aller_bold', 'Kanit', sans-serif;
      color: #FFF;
      ${fluidSizing('font-size', { SM: 28, M: 28, T: 42, D: 52 })}
      margin-top: 40px;
      margin-bottom: 10px;

    }

    p {
      font-weight: 300;
      line-height: 1.2;
      ${fluidSizing('font-size', { SM: 12, M: 14, T: 19, D: 19 })}
    }
  }

  ${breakpoint('T')} {
  }

  ${breakpoint('M')} {
    .slider {
      .mobile {
        display: block;
      }
      .desktop,
      .prev,
      .next {
        display: none;
      }
    }

    .slick-dots {
      bottom: 20px;
    }

    .menu-item-slide {
      flex-direction: column;
      padding: 20px;
      height: 345px;

      .caption {
        max-width: 180px;
        margin: 0;

        p {
          margin-top: 0;
        }
      }
    }
  }
`

export default Menu
