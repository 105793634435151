import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'

const Button = (props) => {
  if (props.loading) {
    return <Style className='btn'>Please wait...</Style>
  }

  return (
    <Style className='btn' background={props.background} onClick={props.onClick}>
      {props.children}
    </Style>
  )
}

Button.propTypes = {
  onClick: PropTypes.func,
  background: PropTypes.string
}

Button.defaultProps = {
  onClick: () => null,
  background: '#4b0585'
}

export default Button

const Style = styled.button`
  label: Button;

  color: #fff;
  background: ${(p) => p.background};
  border-radius: 10.3px;
  padding: 15px 20px;
  font-size: 16px;
  border: none;
  display: inline-block;
  cursor: pointer;
`
