import { Route, Switch, useLocation } from 'react-router-dom'
import React from 'react'
import { useTransition, animated } from 'react-spring'

import Home from './pages/Home/index.js'
import WhereToBuy from './pages/WhereToBuy'
import PageNotFound from './pages/PageNotFound'
import Brand from './pages/Brand.js'
import AboutUs from './pages/AboutUs/index.js'
import PrivacyPolicy from './pages/PrivacyPolicy'
import FAQ from './pages/FAQ'
import NewsAndPromotion from './pages/NewsAndPromotion'
import NewsDetail from './pages/NewsDetail'
import Contact from './pages/Contact'
import Menu from './pages/Menu'

const Routes = () => {
  const location = useLocation()
  const transitions = useTransition(location, (location) => location.pathname, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 100 }
  })

  return transitions.map(({ item: location, props, key }) => (
    <animated.div key={key} style={props}>
      <Switch location={location}>
        <Route exact path='/' component={Home} />
        <Route exact path='/about-us' component={AboutUs} />
        <Route exact path='/brand' component={Brand} />
        <Route exact path='/where-to-buy' component={WhereToBuy} />
        <Route exact path='/faq' component={FAQ} />
        <Route exact path='/news-promotion' component={NewsAndPromotion} />
        <Route exact path='/news-promotion/:slug' component={NewsDetail} />
        <Route exact path='/menu' component={Menu} />
        <Route exact path='/contact-us' component={Contact} />
        <Route exact path='/privacy-policy' component={PrivacyPolicy} />
        <Route component={PageNotFound} />
      </Switch>
    </animated.div>
  ))
}

export default Routes
