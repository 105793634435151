import styled from '@emotion/styled'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { paddingFooter } from '../components/Footer'
import { breakpoint, fluidSizing } from '../utils'
import Collapse from '../components/Collapse'

const PrivacyPolicy = () => {
  const appData = useSelector((state) => state.data)

  if (isEmpty(appData.content)) return null

  const { content } = appData

  return (
    <>
      <Helmet>
        <title>Privacy Policy</title>
        <meta name='title' content='Privacy Policy' />
        <meta name='description' content='' />
      </Helmet>

      <img src='/images/img-banner-privacy.webp' width='100%' alt='' />
      <Style>
        <div className='content'>
          <div className='container'>
            {content.privacy_policy_list.map((item, i) => (
              <Collapse key={i} title={item.title} maxFontSize={45}>
                {item.content}
              </Collapse>
            ))}
          </div>
        </div>
      </Style>
    </>
  )
}

const Style = styled('div')`
  label: PrivacyPolicy;

  position: relative;
  background: #fff url('/images/img-bg-privacy.webp') center center no-repeat;
  background-size: cover;

  .content {
    position: relative;
    padding: 60px 20px;
  }

  .box-detail {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding-left: 150px;

    > h1 {
      ${fluidSizing('font-size', { SM: 20, M: 20, T: 45, D: 45 })}
      color: #482c89;

      > span {
        color: #993486;
      }
    }

    > p {
      font-family: 'Kanit';
      font-weight: 300;
      line-height: 1.4;
      color: #4b0585;
      ${fluidSizing('font-size', { SM: 14, M: 14, T: 19, D: 19 })}
    }
  }

  ${breakpoint('M')} {
    .content {
      position: relative;
      /* padding: 160px 20px; */
    }
  }

  ${paddingFooter}
`

export default PrivacyPolicy
