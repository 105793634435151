import styled from '@emotion/styled'
import { gsap } from 'gsap'
import { useEffect, useRef } from 'react'
import { breakpoint, fluidSizing } from '../../utils'
import Button from '../../components/Button'
import Follow from '../../components/Follow'
import { useHistory } from 'react-router'

const Welcome = (props) => {
  const pageRef = useRef()
  let history = useHistory()

  useEffect(() => {
    const page = pageRef.current
    const animationSelectors = page.querySelectorAll('.animate')

    gsap.fromTo(
      animationSelectors,
      {
        opacity: 0,
        y: 40
      },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        stagger: 0.2
      }
    )
  }, [])

  return (
    <Style ref={pageRef}>
      <img src='/images/ico-bean-white.webp' alt='' className='icon-bean-1 animate' />

      <div className='container'>
        <div className='row'>
          <div className='D-6 M-12'>
            <img className='welcome-image animate' src='/images/img-welcome.webp' alt='' />
          </div>
          <div className='D-6 M-12'>
            <div className='welcome-caption'>
              <h4 className='animate'>BENAS … Twist of Taste</h4>
              <h1 className='animate'>Edamame Spread with White Chocolate</h1>
              <div className='welcome-action-group animate'>
                <Button onClick={() => history.push('/about-us')}>About Us</Button>
                <Follow />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='copyright'>
        {/* <img src='/images/ico-arrow-down.webp' alt='' className='arrow-down' /> */}
        <div>
          COPYRIGHT © 2021 CHIANGMAI FROZEN FOODS PUBLIC COMPANY LIMITED. All rights reserved
        </div>
      </div>
      <img src='/images/ico-bean-white.webp' alt='' className='icon-bean-2 animate' />
    </Style>
  )
}

const Style = styled('div')`
  label: Welcome;

  position: relative;
  z-index: 1;
  height: 100vh;
  background: #fff url('/images/top-bg.webp') center center no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;

  .copyright {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    font-size: 11px;
    padding: 18px;
  }

  .arrow-down {
    width: 22px;
    margin-bottom: 20px;
  }

  .welcome-image {
    width: 100%;
    max-width: 675px;
    margin-bottom: -250px;
  }

  .welcome-caption {
    width: 100%;
    max-width: 520px;

    > h4 {
      ${fluidSizing('font-size', { SM: 16, M: 22, D: 30 })}
      color: #4b0585;
      font-weight: bold;
      margin-bottom: 0;
      /* line-height: 1.2; */
    }

    > h1 {
      ${fluidSizing('font-size', { SM: 27, M: 27, D: 42 })}
      font-family: 'GothamRoundedMedium';
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: 2.14px;
      margin: 15px 0;
    }
  }

  .welcome-action-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > .btn {
      margin-bottom: 20px;
    }
  }

  .icon-bean-1,
  .icon-bean-2 {
    position: absolute;
    width: 263px;
  }

  .icon-bean-1 {
    right: 127px;
    top: -100px;
  }

  .icon-bean-2 {
    right: 127px;
    bottom: -140px;
  }

  ${breakpoint('T')} {
  }

  ${breakpoint('M')} {
    background: #fff url('/images/top-bg-mobile.webp') center center no-repeat;
    background-size: cover;

    .welcome-caption {
      max-width: 282px;
      margin: -40px auto 0;
    }

    .welcome-image {
      max-width: 375px;
      margin: 0 auto;
      display: block;
    }

    .welcome-action-group {
      flex-direction: row;

      > .btn {
        margin-right: 20px;
      }
    }

    .copyright {
      font-size: 7px;
    }

    .arrow-down {
      width: 13px;
    }

    .icon-bean-1,
    .icon-bean-2 {
      display: none;
    }
  }
`

export default Welcome
