import { Helmet } from 'react-helmet'
import { useController, useForm } from 'react-hook-form'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
// import countryList from 'react-select-country-list'
import styled from '@emotion/styled'
import Select from 'react-select'
import qs from 'qs'

import { breakpoint, fluidSizing } from '../utils'
import { paddingFooter } from '../components/Footer'
import Translate from '../components/Translate'
import Modal, { openModal } from '../components/Modal'
import Button from '../components/Button'
import { getReviews } from '../actions/action'
import { fetchSendMail } from '../api/fetchers'
import { useTranslation } from 'react-i18next'
// import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import ReactFlagsSelect from 'react-flags-select';

const phoneFormat = /^0([1-9]{1})([0-9]{8})$/
const emailFormat =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const lengthLimit = /^.{1,500}$/

const messagePlaceholder = {
  en: 'Maximum 500 characters',
  th: 'ไม่เกิน 500 ตัวอักษร'
}
const selectCountryPlaceHolder = {
  en: 'Select Country',
  th: 'เลือกประเทศ'
}
const selectTopicPlaceHolder = {
  en: 'Select Topic',
  th: 'เลือกหัวข้อ'
}

const Contact = () => {
  const [selected, setSelected] = useState('');
  const { i18n } = useTranslation()
  const [sending, setSending] = useState(false)
  const dispatch = useDispatch()
  const { control, reset, handleSubmit } = useForm()
  // const countryOptions = useMemo(() => countryList().getData(), [])

  useEffect(() => {
    dispatch(getReviews())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const inputName = useController({
    control,
    name: 'fullname',
    defaultValue: '',
    rules: {
      required: '*Please enter name'
    }
  })

  const inputPhoneNumber = useController({
    control,
    name: 'phonenumber',
    defaultValue: '',
    rules: {
      // required: '*Please enter phone number',
      pattern: {
        value: phoneFormat,
        message: '*Please enter a valid phone number'
      }
    }
  })

  const inputEmail = useController({
    control,
    name: 'email',
    defaultValue: '',
    rules: {
      required: '*Please enter email',
      pattern: {
        value: emailFormat,
        message: '*Please enter a valid email address'
      }
    }
  })

  const inputCountry = useController({
    control,
    name: 'country',
    defaultValue: '',
    // rules: {
    //   required: '*Please enter country'
    // }
  })

  const inputTopic = useController({
    control,
    name: 'topic',
    defaultValue: '',
    rules: {
      required: '*Please enter topic'
    }
  })

  const inputMessage = useController({
    control,
    name: 'message',
    defaultValue: '',
    rules: {
      required: '*Please enter message',
      pattern: {
        value: lengthLimit,
        message: '*Please do not add more than 500 characters'
      }
    }
  })

  const onSubmit = async (formData) => {
    console.log('Form data', formData)
    if (selected.length <= 0) return
    formData.country = selected
    const dataToSend = {
      action: 'send_email_contact',
      ...formData,
      topic: formData.topic.value,
      country: formData.country.value
    }

    try {
      setSending(true)
      const res = await fetchSendMail(qs.stringify(dataToSend))

      if (res.status === 200) {
        reset({
          fullname: '',
          email: '',
          country: '',
          phonenumber: '',
          topic: '',
          message: ''
        })
        openModal('create-review-success')
      } else {
        openModal('error')
      }
    } catch (error) {
      openModal('error')
    } finally {
      setSending(false)
    }
  }

  const topics = [
    {
      en: 'Recommend product',
      th: 'แนะนำผลิตภัณฑ์'
    },
    {
      en: 'Interested in product',
      th: 'สนใจผลิตภัณฑ์'
    },
    {
      en: 'Product Complaint',
      th: 'ร้องเรียนผลิตภัณฑ์'
    },
    {
      en: 'Others',
      th: 'อื่นๆ'
    }
  ]

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: '#000',
    })
  }

  return (
    <>
      <Helmet>
        <title>Contact Us</title>
        <meta name='title' content='Contact Us' />
        <meta name='description' content='' />
      </Helmet>

      <Style>
        <div className='box-contact'>
          <div className='image'></div>
          <div className='detail'>
            <h1>CONTACT US</h1>
            <p>
              <Translate
                en='Should you have any additional questions about BENAS, please fill out the form below'
                th='ในกรณีที่มีคำถามเพิ่มเติมเกี่ยวกับบีนาส โปรดกรอกแบบฟอร์มด้านล่าง'
              />
            </p>

            <div className='form'>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='field-item'>
                  <div className='label required'>
                    <Translate en='Fullname' th='ชื่อ - นามสกุล' />
                  </div>
                  <div className='input'>
                    <input type='text' {...inputName.field} />
                    <div className='error-message'>{inputName.fieldState.error?.message}</div>
                  </div>
                </div>
                <div className='field-item'>
                  <div className='label'>
                    <Translate en='Phone number' th='เบอร์โทร' />
                  </div>
                  <div className='input'>
                    <input type='text' {...inputPhoneNumber.field} />
                    <div className='error-message'>
                      {inputPhoneNumber.fieldState.error?.message}
                    </div>
                  </div>
                </div>
                <div className='field-item'>
                  <div className='label required'>
                    <Translate en='Email' th='อีเมล' />
                  </div>
                  <div className='input'>
                    <input type='text' {...inputEmail.field} />
                    <div className='error-message'>{inputEmail.fieldState.error?.message}</div>
                  </div>
                </div>
                <div className='field-item'>
                  <div className='label required'>
                    <Translate en='Country' th='ประเทศ' />
                  </div>
                  <div className='input'>
                    <ReactFlagsSelect
                      placeholder={selectCountryPlaceHolder[i18n.language]}
                      searchable
                      selected={selected}
                      onSelect={code => setSelected(code)}
                      className="input-select-flags"
                      {...inputCountry.field}
                    />
                    {/* <Select
                      {...inputCountry.field}
                      styles={customStyles}
                      placeholder={selectCountryPlaceHolder[i18n.language]}
                      options={countryOptions.map((item, i) => ({
                        value: item.value,
                        label: `${getUnicodeFlagIcon(item.value)} ${item.label}`
                      }))}
                    /> */}
                    {/* <select {...inputCountry.field}>
                      <option value=''>Select Country</option>
                      {countryOptions.map((item, i) => (
                        <option key={i} value={item.value}>
                          {getUnicodeFlagIcon(item.value)} {item.label}
                        </option>
                      ))}
                    </select> */}
                    <div className='error-message'>{inputCountry.fieldState.error?.message}</div>
                  </div>
                </div>
                <div className='field-item'>
                  <div className='label required'>
                    <Translate en='Topic' th='ฉันต้องการถามเกี่ยวกับ' />
                  </div>
                  <div className='input'>
                    <Select
                      {...inputTopic.field}
                      styles={customStyles}
                      placeholder={selectTopicPlaceHolder[i18n.language]}
                      options={topics.map((item, i) => ({
                        value: topics[i][i18n.language],
                        label: topics[i][i18n.language]
                      }))}
                    />
                    {/* <select {...inputTopic.field}>
                      <option value=''>Select Topic</option>
                      {topics.map((item, i) => {
                        return (
                          <option key={i} value={topics[i][i18n.language]}>
                            {topics[i][i18n.language]}
                          </option>
                        )
                      })}
                    </select> */}
                    <div className='error-message'>{inputTopic.fieldState.error?.message}</div>
                  </div>
                </div>
                <div className='field-item'>
                  <div className='label required'>
                    <Translate en='Message' th='ข้อความ' />
                  </div>
                  <div className='input'>
                    <textarea
                      type='text'
                      rows='6'
                      maxlength="500"
                      {...inputMessage.field}
                      placeholder={messagePlaceholder[i18n.language]}
                    />
                    <div className='error-message'>{inputMessage.fieldState.error?.message}</div>
                    <div className='box-submit'>
                      <Button fullwidth loading={sending}>
                        <Translate en='Send' th='ส่ง' />
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
              <div className='address'>
                <p>
                  <Translate
                    en='Chiangmai Frozen Foods Public Company Limited'
                    th='บริษัท เชียงใหม่โฟรเซ่นฟูดส์ จำกัด (มหาชน)'
                  />
                </p>
                <p>
                  <Translate
                    en='149/34 Soi Anglo Plaza, Surawong Rd., Suriyawong, Bangrak, Bangkok 10500'
                    th='149/34 ซอย แองโกลพลาซ่า ถนนสุรวงศ์ แขวงสุริยวงศ์ เขตบางรัก กรุงเทพมหานคร 10500'
                  />
                </p>
                <p>
                  <Translate en='Tel 02-634-0061' th='โทรศัพท์ 02-634-0061' />
                  <br />
                  <Translate en='Fax 02-238-4090' th='โทรสาร 02-238-4090' />
                </p>
              </div>
            </div>
          </div>
        </div>
      </Style>

      <Modal name='create-review-success' width='600px'>
        <Translate
          en={
            <p>
              Thank you for your interest in our product. <br />
              We have received your message. <br />
              We will respond to your message as soon as possible.
            </p>
          }
          th={
            <p>
              ขอบคุณที่สนใจในผลิตภัณฑ์ของเรา
              <br />
              เราได้รับข้อความของคุณแล้ว
              <br />
              เราจะพยายามตอบกลับให้เร็วที่สุด
            </p>
          }
        />
      </Modal>
    </>
  )
}

const Style = styled('div')`
  label: Contact;

  position: relative;
  font-family: 'Kanit';
  font-weight: 300;

  h1 {
    ${fluidSizing('font-size', { SM: 20, M: 20, T: 35, D: 45 })}
    font-family: 'aller_bold', 'Kanit', sans-serif;
  }

  p {
    ${fluidSizing('font-size', { SM: 11, M: 11, T: 19, D: 19 })}
    line-height: 1.53;
  }

  .box-contact {
    min-height: 100vh;
    display: flex;

    .image {
      background: #fff url('/images/img-product-contact.webp') center center no-repeat;
      background-size: cover;
      width: 50%;
    }

    .detail {
      background: #6fab27 url('/images/img-bg-contact.webp') bottom right no-repeat;
      background-size: contain;
      width: 50%;
      padding: 100px 40px;
    }
  }

  .address {
    text-align: center;
    margin-top: 80px;
  }

  .form {
    margin-top: 40px;
  }

  .box-submit {
    margin-top: 10px;
    text-align: right;
  }

  .error-message {
    color: #ff0000;
    font-size: 11px;
  }

  .react-select__option {
    color: #000 !important;
  }

  .field-item {
    display: flex;
    margin-bottom: 15px;

    .label {
      margin-right: 20px;
      flex-shrink: 0;
      ${fluidSizing('width', { SM: 70, M: 70, T: 150, D: 150 })}
      ${fluidSizing('font-size', { SM: 11, M: 11, T: 19, D: 19 })}

      &.required::after {
        color: #ff0000;
        content: '*';
      }
    }

    .input {
      flex-grow: 1;
    }

    input,
    select {
      width: 100%;
      border: none;
      background: none;
      border-bottom: 1px solid #fff;
      outline: none;
      /* color: #fff; */
      ${fluidSizing('font-size', { SM: 11, M: 11, T: 19, D: 19 })}
    }

    textarea {
      width: 100%;
      background: none;
      border: 1px solid #fff;
      outline: none;
      border-radius: 10px;
      padding: 10px;
      color: #fff;
      ${fluidSizing('font-size', { SM: 11, M: 11, T: 19, D: 19 })}
      ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #fff;
        opacity: 0.6; /* Firefox */
      }
      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #fff;
        opacity: 0.6;
      }
      ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #fff;
        opacity: 0.6;
      }
    }
  }

  ${breakpoint('M')} {
    .box-contact {
      height: auto;

      .image {
        display: none;
      }

      .detail {
        width: 100%;
        padding: 60px 20px;
      }
    }
  }

  ${paddingFooter}

  .input-select-flags {
    color: #4b0585;
    #rfs-btn {
      color: #FFF;
    }
    ul {
      div > input {
        border-color: #4b0585;
      }
    }
  }
`

export default Contact
