import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { Fragment } from 'react'
import { getContent } from '../../actions/action'
import { useDispatch } from 'react-redux'

const languages = [
  {
    label: 'TH',
    key: 'th',
    flag: '/images/ico-th.png'
  },
  {
    label: 'EN',
    key: 'en',
    flag: '/images/ico-en.png'
  }
]

const LangSwitcher = (props) => {
  const dispatch = useDispatch()
  const { i18n } = useTranslation()

  return (
    <Style className='language-switcher'>
      <div className='flag'>
        {languages.map((item, i) => (
          <Fragment key={i}>
            {i18n.language === item.key && <img src={item.flag} alt='' />}
          </Fragment>
        ))}
      </div>

      <div className='box-lang-text'>
        {languages.map((item, i) => (
          <Fragment key={i}>
            {i !== 0 && '/'}
            <span
              className={`${i18n.language === item.key && 'active'}`}
              onClick={() => {
                i18n.changeLanguage(item.key)
                dispatch(getContent({ lang: item.key }))
              }}
            >
              {item.label}
            </span>
          </Fragment>
        ))}
      </div>
    </Style>
  )
}

const Style = styled.div`
  label: LangSwitcher;

  text-align: center;

  img {
    width: 32px;
    display: inline-block;
  }

  .box-lang-text {
    display: flex;
    align-items: center;
    font-size: 14px;

    span {
      color: #ccc;
      cursor: pointer;
      display: inline-block;
      padding: 0 5px;
    }

    span.active {
      color: #fff;
    }
  }
`

export default LangSwitcher
