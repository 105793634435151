import { createActionWithFetching } from '../utils'
import {
  fetchContent,
  fetchReviews,
  fetchWhereToBuy,
  fetchNews,
  fetchMenuBanner,
  fetchMenus
} from '../api/fetchers'
import dataModule from '../modules/data'

const getContent = ({ lang }) => {
  const callAction = async (dispatch) => {
    const params = {
      lang
    }

    const [content, whereToBuy, news, menuBanner, menus] = await Promise.all([
      fetchContent(params),
      fetchWhereToBuy(params),
      fetchNews(params),
      fetchMenuBanner(params),
      fetchMenus(params)
    ])

    dispatch(
      dataModule.set({
        key: 'content',
        value: content.data.acf
      })
    )

    dispatch(
      dataModule.set({
        key: 'whereToBuy',
        value: whereToBuy.data
      })
    )

    dispatch(
      dataModule.set({
        key: 'news',
        value: news.data
      })
    )

    dispatch(
      dataModule.set({
        key: 'menuBanner',
        value: menuBanner.data
      })
    )

    dispatch(
      dataModule.set({
        key: 'menus',
        value: menus.data
      })
    )
  }

  return createActionWithFetching({
    callAction
  })
}

const getReviews = () => {
  const callAction = async (dispatch) => {
    const review = await fetchReviews()

    dispatch(
      dataModule.set({
        key: 'reviews',
        value: review.data
      })
    )
  }

  return createActionWithFetching({
    callAction
  })
}

export { getContent, getReviews }
