import styled from '@emotion/styled'
import { useEffect, useRef } from 'react'
import { breakpoint, fluidSizing, history } from '../../utils'
import Slider from 'react-slick'
import { useSelector } from 'react-redux'
import { paddingFooter } from '../../components/Footer'
import { gsap } from 'gsap'

const promotionSettings = {
  dots: false,
  arrows: false,
  fade: false,
  autoplay: false,
  infinite: true,
  speed: 500,
  autoplaySpeed: 4000,
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        dots: true
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: true
      }
    }
  ]
}

const NewsAndPromotionSlider = ({ isHome = false }) => {
  const sliderPromotionRef = useRef()
  const pageRef = useRef()

  const appData = useSelector((state) => state.data)

  useEffect(() => {
    const page = pageRef.current
    setTimeout(() => {
      const animationSelectors = page.querySelectorAll('.animate')
      gsap.fromTo(
        animationSelectors,
        {
          opacity: 0,
          y: 120
        },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          stagger: 0.2,
          scrollTrigger: {
            trigger: page,
            start: 'top bottom-=200',
            once: true
          }
        }
      )
    }, 300)
  }, [])

  const { news } = appData

  const handleClickNews = (news) => {
    history.push(`/news-promotion/${news.slug}`)
  }

  const shouldMakeSlider = (datas) => {
    if (datas.length > 3) return true
    return false
  }
  const otherItems = news.filter((item) => !item.acf.pin)

  const items = isHome ? news : otherItems

  return (
    <Style ref={pageRef} isHome={isHome}>
      <div className='box-promotion'>
        <div className='container'>
          <h1 className='animate'>NEWS & PROMOTION</h1>
          <div className='row'>
            <div className='D-8 T-12 animate' style={{ position: 'relative', zIndex: '1' }}>
              <div className='slider-promotion'>
                {shouldMakeSlider(items) && (
                  <>
                    <img
                      className='prev'
                      onClick={() => sliderPromotionRef.current.slickPrev()}
                      src='/images/ico-arrow-slider-left.webp'
                      alt=''
                    />
                    <img
                      className='next'
                      onClick={() => sliderPromotionRef.current.slickNext()}
                      src='/images/ico-arrow-slider-right.webp'
                      alt=''
                    />
                  </>
                )}
                {shouldMakeSlider(items) ? (
                  <Slider ref={sliderPromotionRef} {...promotionSettings}>
                    {items.map((item, i) => (
                      <div key={i}>
                        <div className='item-promotion-slide'>
                          <div className='promotion-card'>
                            <div className='image'>
                              <img src={item.acf.featured_image} alt='' />
                              <img src='/images/img-arc-item.webp' alt='' className='arc' />
                            </div>
                            <div className='detail'>
                              <h3>
                                <span>+</span> {item.title.rendered}
                              </h3>
                              <p>{item.acf.short_description}</p>
                              <div className='readmore' onClick={() => handleClickNews(item)}>
                                READ MORE +
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                ) : (
                  <div className='promotion-list'>
                    {items.map((item, i) => (
                      <div key={i} className='item-promotion-slide'>
                        <div className='promotion-card'>
                          <div className='image'>
                            <img src={item.acf.featured_image} alt='' />
                            <img src='/images/img-arc-item.webp' alt='' className='arc' />
                          </div>
                          <div className='detail'>
                            <h3>
                              <span>+</span> {item.title.rendered}
                            </h3>
                            <p>{item.acf.short_description}</p>
                            <div className='readmore' onClick={() => handleClickNews(item)}>
                              READ MORE +
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className='D-4 hidden-T animate'>
              <img src='/images/ico-product-about.webp' alt='' width='100%' />
            </div>
          </div>
        </div>
      </div>
    </Style>
  )
}

const Style = styled('div')`
  label: NewsAndPromotionSlider;

  .box-promotion {
    padding-top: 180px;
    background-image: url(${(p) => p.isHome ? '/images/img-news-promotion-home.webp' : '/images/img-bg-news-promotion.webp'});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding: 90px;

    h1 {
      width: 200px;
      ${fluidSizing('font-size', { SM: 20, M: 20, T: 35, D: 45 })}
      color: ${(p) => (p.isHome ? '#ffffff' : '#482c89')};
    }

    ${(p) => !p.isHome && paddingFooter}
  }

  .promotion-list {
    display: flex;
  }

  .slider-promotion {
    position: relative;
    margin: 0 -15px;

    .prev {
      position: absolute;
      z-index: 1;
      top: 50%;
      left: -30px;
      transform: translateY(-50%);
      width: 27px;
      cursor: pointer;
    }

    .next {
      position: absolute;
      z-index: 1;
      top: 50%;
      right: -30px;
      transform: translateY(-50%);
      width: 27px;
      cursor: pointer;
    }
  }

  .item-promotion-slide {
    padding: 0 15px;
  }

  .promotion-card {
    max-width: 300px;
    width: 100%;
    border-radius: 30px;
    overflow: hidden;
    background: #e1dfd9;

    > .image {
      position: relative;

      img {
        width: 100%;
      }

      .arc {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
      }
    }

    > .detail {
      padding: 0 10px 15px;
      font-family: 'Kanit';
      color: #482c89;

      h3 {
        ${fluidSizing('font-size', { SM: 14, M: 14, T: 18, D: 23 })}
        margin-top: 0;
        margin-bottom: 0;

        > span {
          color: #993486;
        }
      }

      p {
        margin-top: 5px;
        margin-bottom: 5px;
        font-weight: 300;
        line-height: 1.2;
        max-height: 100px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        ${fluidSizing('font-size', { SM: 14, M: 14, T: 19, D: 19 })}
      }

      .readmore {
        color: #ffffff;
        background: #993486;
        border-radius: 14px;
        font-size: 18px;
        padding: 0px 10px;
        display: inline-block;
        cursor: pointer;
      }
    }
  }

  ${breakpoint('T')} {
    .slider-promotion {
      padding: 20px;

      .prev,
      .next {
        display: none;
      }
    }
  }

  ${breakpoint('M')} {
    .box-promotion {
      padding: 60px 20px;
    }

    .slider-promotion {
      padding: 0;
    }
  }
`

export default NewsAndPromotionSlider
