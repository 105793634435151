import styled from '@emotion/styled'
import { Helmet } from 'react-helmet'
import { breakpoint } from '../../utils'
import TopBanner from './TopBanner'
import InsideAndInspiration from './InsideAndInspiration'

const AboutUs = () => {
  // const appData = useSelector((state) => state.data)

  // if (isEmpty(appData.content)) return null

  // const { content } = appData

  return (
    <>
      <Helmet>
        <title>About Us</title>
        <meta name='title' content='About Us' />
        <meta name='description' content='' />
      </Helmet>

      <Style>
        <TopBanner />
        <InsideAndInspiration />
      </Style>
    </>
  )
}

const Style = styled('div')`
  label: AboutUs;

  ${breakpoint('M')} {
  }
`

export default AboutUs
