import styled from '@emotion/styled'
import { gsap } from 'gsap'
import { useEffect, useRef } from 'react'
import { breakpoint, fluidSizing } from '../../utils'
import Translate from '../../components/Translate'

const Introduction = (props) => {
  const pageRef = useRef()

  useEffect(() => {
    const page = pageRef.current
    setTimeout(() => {
      const animationSelectors = page.querySelectorAll('.animate')
      gsap.fromTo(
        animationSelectors,
        {
          opacity: 0,
          y: 120
        },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          stagger: 0.2,
          scrollTrigger: {
            trigger: page,
            start: 'top bottom-=200',
            once: true
          }
        }
      )
    }, 300)
  }, [])

  return (
    <Style ref={pageRef}>
      <div className='container'>
        <div className='row'>
          <div className='D-6 M-12 vertical-middle'>
            <div className='box-introcution-image animate'>
              <img
                className='introduction-image'
                src='/images/ico-product-introduction.webp'
                alt=''
              />
            </div>
          </div>
          <div className='D-6 M-12 vertical-middle'>
            <div className='introduction-caption animate'>
              <h1>BENAS – TWIST OF TASTE</h1>
              <div className='detail'>
                <Translate
                  en={
                    <>
                      <p>
                        “BENAS” Edamame Spread with White Chocolate made from premium edamame,
                        characterized by a smooth green texture combined with white chocolate of a
                        mild taste turned into a fun and twisted spread product which is very
                        unique. A sweet aroma of white chocolate is scented and a creamy taste of
                        edamame is felt on the tip of the tongue. This is the origin of the slogan
                        “Twist of Taste,” which means that immature soybeans are processed in a new
                        way, adding gimmicks to create a unique and delicious taste. It is also a
                        bread spread with valuable ingredients which have been popular from the
                        Eastern Hemisphere.  
                      </p>
                      <p>
                        Japanese soybeans or immature soybeans which most people are familiar with
                        and call it "Edamame" is one of the most popular Japanese dishes. It is
                        classified as a Superfood that is rich in protein, packed with the benefits
                        of vitamins, antioxidant as well as minerals that are essential for human
                        health. Moreover, it is a food menu that health-conscious people around the
                        world fall in love with. These days, edamame is gaining popularity and
                        well-loved both domestically and internationally due to the popularity of
                        soft culture that has been derived from Japanese dining culture and now
                        becomes a lifestyle.
                      </p>
                    </>
                  }
                  th={
                    <>
                      <p>
                        “BENAS” (บีนาส) ผลิตภัณฑ์ทาขนมปังถั่วแระญี่ปุ่นผสม ไวท์ช็อกโกแลต
                        ผลิตจากถั่วแระญี่ปุ่น คุณภาพดี
                        มีเนื้อสัมผัสสีเขียวเนียนละเอียดผสมผสานไวท์ช็อกโกแลตรสชาติแสนละมุน
                        กลายมาเป็นผลิตภัณฑ์ทาขนมปังรสชาติแสนสนุกสุดทวิสต์ที่ไม่ธรรมดา
                        หวานหอมกลิ่นไวท์ช็อกโกแลต ได้รสสัมผัสความหอมมันของถั่วแระที่ปลายลิ้น
                        ซึ่งเป็นที่มาของสโลแกน “Twist of Taste” หมายถึง
                        การนำถั่วแระญี่ปุ่นมาแปรรูปในรูปแบบใหม่ๆ
                        เติมลูกเล่นให้เกิดรสชาติอร่อยแหวกแนวไม่ซ้ำใคร
                        และยังเป็นผลิตภัณฑ์ทาขนมปังที่ใช้วัตถุดิบที่มีคุณค่าและเป็นที่นิยมจากซีกโลกตะวันออกมาเป็นเวลานาน
                      </p>
                      <p>
                        ถั่วแระญี่ปุ่น หรือ ถั่วเหลืองฝักอ่อน
                        ที่คนส่วนใหญ่คุ้นเคยและเรียกกันจนติดปากว่า “เอดะมาเมะ” (Edamame)
                        เป็นหนึ่งในอาหารยอดนิยมของชาวญี่ปุ่น จัดว่าเป็นซูเปอร์ฟู้ด (Superfood)
                        ที่อุดมไปด้วยโปรตีนสูง อัดแน่นด้วยคุณประโยชน์จากวิตามิน สารต้านอนุมูลอิสระ
                        ตลอดจนแร่ธาตุที่จำเป็นต่อร่างกาย
                        ทั้งยังเป็นเมนูอาหารที่คนรักสุขภาพทั่วโลกหลงรัก
                        ปัจจุบันถั่วแระญี่ปุ่นได้รับความนิยมและเป็นที่ชื่นชอบอย่างมากทั้งภายในประเทศและต่างประเทศ
                        เนื่องจากกระแสนิยมของ Soft Culture
                        ที่ได้รับจากวัฒนธรรมการรับประทานอาหารญี่ปุ่นจนกลายมาเป็นไลฟ์สไตล์แบบหนึ่ง
                      </p>
                    </>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Style>
  )
}

const Style = styled('div')`
  label: Introduction;

  position: relative;
  min-height: 100vh;
  background: #fff url('/images/img-section-introduction.webp') center center no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;

  .box-introcution-image {
    display: flex;
    justify-content: center;
  }

  .introduction-image {
    width: 100%;
    max-width: 575px;
  }

  .introduction-caption {
    width: 100%;
    max-width: 545px;

    > h1 {
      ${fluidSizing('font-size', { SM: 20, M: 20, D: 45 })}
      margin-top: 0;
      margin-bottom: 1.6rem;
    }

    > .detail {
      height: 320px;
      overflow: auto;
      font-family: 'Kanit';
      font-weight: 300;
      line-height: 1.42;
      padding-right: 15px;
      scrollbar-width: thin;
      scrollbar-color: #ddd rgb(255 255 255 / 20%);
      ${fluidSizing('font-size', { SM: 14, M: 14, D: 19 })}

      p {
        margin-top: 0;
      }

      &::-webkit-scrollbar {
        width: 3px;
      }

      &::-webkit-scrollbar-track {
        background: rgb(255 255 255 / 20%);
      }

      &::-webkit-scrollbar-thumb {
        background-color: #ddd;
        border-radius: 100px;
      }
    }
  }

  ${breakpoint('T')} {
  }

  ${breakpoint('M')} {
    background: #fff url('/images/img-section-introduction-mobile.webp') center center no-repeat;
    background-size: cover;

    .introduction-caption {
      max-width: 100%;
      margin: 0 auto;

      > .detail {
        height: 200px;
      }
    }

    .introduction-image {
      max-width: 375px;
      margin: 20px auto;
      display: block;
    }
  }
`

export default Introduction
